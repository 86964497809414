<template>
	<div class="mainBox">
		<img :src="banner" style="width: 100%;height: auto;" />
		<div class="formBox" v-for="(item,index) in cardList" v-if="cardList.length > 0">
			<van-cell-group inset>
				<div class="title">{{item.zeo_name}}</div>
				<van-field v-model="item.ordersn" label="订单号" label-width="70px" readonly />
				<van-field v-model="item.card" label="卡号" label-width="70px" readonly />
				<van-field v-model="item.flagtime" label="兑换时间" label-width="70px" readonly />
				<van-field v-model="item.statusName" label="订单状态" label-width="70px" readonly />
				<div v-if="item.status == 1" class="statusBox">您的兑换信息已到达发货平台，库房将在48小时内完成发货（节假日可能会有延迟），请您耐心等待。</div>
			</van-cell-group>
			<div style="margin: 16px;">
				<van-button round block type="primary" native-type="submit" color="#000000"
					@click="express(item.ordersn)" size="small">
					查看详情
				</van-button>
			</div>
			<!-- 'https://m.baidu.com/s?ie=utf-8&wd=' + order + '&com=auto' -->
		</div>
		<div class="formBox" v-else>
			<div style="padding: 80px 50px;">
				<van-loading size="24px" type="spinner" v-if="!loading" vertical>订单加载中</van-loading>
			</div>
			<van-empty image="search" description="暂无家油卡订单" v-if="loading" />
			<van-button @click="back">返回主页</van-button>
		</div>
		<!-- 		<van-popup v-model:show="show" :style="{ height: '90%' ,width:'90%',padding:'0px'}">
			<iframe :src="url" style="border: none;height: 100%;"></iframe>
		</van-popup> -->
		<div class="footer">&copy;Sanmark<div class="istero" @click="toistero">起零公司提供技术支持</div>
		</div>
	</div>
</template>
<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		showToast,
		showDialog
	} from 'vant';
	import {
		showConfirmDialog
	} from 'vant';

	import {
		areaList
	} from '@vant/area-data';

	import {
		getBanner,
		getOrderListNew
	} from '@/api/home';
	export default {
		name: 'App',
		data() {
			return {
				banner: "",
				show: false,
				loading: false,
				cardList: []
			}
		},
		created() {
			let that = this
			that.username = that.$route.query.username
			that.tel = that.$route.query.tel
			that.getOrderList()
			that.getBanner()
		},
		setup() {
			return {

			};

		},
		methods: {
			getBanner() {
				let that = this
				getBanner({}).then(res => {
					if (res.code == 200) {
						that.banner = res.data.zb_img
					}
				})
			},
			back() {
				let that = this
				localStorage.clear();
				localStorage.setItem("access_token", "");
				that.$router.push({
					path: "/"
				})
			},
			express(sn) {
				let that = this
				that.$router.push({
					path: "/order-detail",
					query: {
						sn
					}
				})
				return false
				// let that = this
				// if (orders == '-') {
				// 	showToast("请发货后在进行查询");
				// 	return false
				// } else {
				// 	window.location = "https://m.baidu.com/s?ie=utf-8&wd=" + orders + "&com=auto"
				// }
			},
			formattedTime(date) {
				const year = date.getFullYear();
				const month = ("0" + (date.getMonth() + 1)).slice(-2);
				const day = ("0" + date.getDate()).slice(-2);
				const hour = ("0" + date.getHours()).slice(-2);
				const minute = ("0" + date.getMinutes()).slice(-2);
				const second = ("0" + date.getSeconds()).slice(-2);
				return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
			},
			getOrderList() {
				let that = this
				getOrderListNew({
					username: that.username,
					tel: that.tel
				}).then(res => {
					if (res.code == 300) {
						that.clearLogin()
					} else if (res.code == 200) {
						that.cardList = res.data
						that.loading = true
					}

				})
			},
		}


	}
</script>
<style>
	body {
		background: rgb(24, 24, 24);
	}

	.mainBox {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}



	.mainBox .formBox {
		width: 85%;
		padding: 5px 5px 15px 5px;
		text-align: center;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		border-radius: 10px;
	}

	.mainBox .title {
		font-size: 14px;
		padding: 15px 0px;
	}

	.mainBox .footer {
		font-size: 12px;
		padding: 30px 0px;
		color: #676767;
		text-align: center;
	}

	.mainBox .footer .istero {
		font-size: 11px;
		border-bottom: #4c4c4c solid 1px;
		margin-top: 5px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}

	.mainBox .statusBox {
		padding: 20px;
		font-size: 13px;
		background: #f1f1f1;
		color: #8f8f8f;
		text-align: left;
		margin-top: 10px;
	}
</style>